import { OutputType as OutputAttributeValue } from '../AttributeField';
import { Image } from './image';
import { EbayListing, Listing, OlxListing, VendoraListing } from './listing';

export type FormFields = {
  title: string,
  description: string,
  price: number,
  images: Image[],
  category: string,
  city: string,
  district?: string,
  attributes: { [key: string]: OutputAttributeValue }
};

export type VendoraFormFields = Omit<FormFields, 'city'>;

export type EbayFormFields = Omit<FormFields, 'city' | 'district'>;
export type OlxFormFields = {
  autoRefresh: boolean
} & FormFields;


// Split into two functions when Listing and OlxListing differ enough
export const getInitialFormValues = (existingListing: Listing | undefined): FormFields => ({
  title: existingListing?.title ?? '',
  description: existingListing?.description ?? '',
  price: existingListing?.price ?? 0,
  category: existingListing?.category ?? '',
  city: existingListing?.location.cityId ?? '',
  district: existingListing?.location.districtId ?? '',
  images: existingListing?.images ?? [],
  attributes: existingListing?.attributes
    .reduce((result, { code, value, values }) => ({ ...result, [code]: value ?? values }), {}) ?? {}
});

export const getInitialOlxFormValues = (existingListing: OlxListing | undefined): OlxFormFields => ({
  title: existingListing?.title ?? '',
  description: existingListing?.description ?? '',
  price: existingListing?.price ?? 0,
  category: existingListing?.category ?? '',
  city: existingListing?.location.cityId ?? '',
  district: existingListing?.location.districtId ?? '',
  images: existingListing?.images ?? [],
  attributes: existingListing?.attributes
    .reduce((result, { code, value, values }) => ({ ...result, [code]: value ?? values }), {}) ?? {},
  autoRefresh: existingListing?.autoRefresh ?? false

});

export const getVendoraInitialFormValues = (existingListing: VendoraListing | undefined): VendoraFormFields => ({
  title: existingListing?.title ?? '',
  description: existingListing?.description ?? '',
  price: existingListing?.price ?? 0,
  category: existingListing?.category ?? '',
  images: existingListing?.images ?? [],
  attributes: Object.entries(existingListing?.attributes ?? {})
    .reduce((result, entry) => ({ ...result, [entry[0]]: entry[1] }), {}) ?? {}
});

export const getEbayInitialFormValues = (existingListing: EbayListing | undefined): EbayFormFields => ({
  title: existingListing?.title ?? '',
  description: existingListing?.description ?? '',
  price: existingListing?.price ?? 0,
  category: existingListing?.category ?? '',
  images: existingListing?.images ?? [],
  attributes: Object.entries(existingListing?.attributes ?? {})
    .reduce((result, entry) => ({ ...result, [entry[0]]: entry[1] }), {}) ?? {}
});
