import { Box, Button, Checkbox, FormControlLabel, FormGroup, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Formik } from 'formik';
import { Platform, PLATFORM_NAMES } from './models/listing';
import React, { useEffect } from 'react';
import createAuthRequest from './utils/createAuthRequest';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Template, TemplateUpdateRequest } from './models/template';
import { useSnackbar } from './utils/error-toast-provider';


const FormSwitchToggle = (
  { handleFormSwitch, loadedForm }:
    { handleFormSwitch: (event: React.MouseEvent<HTMLElement>, value: any) => void, loadedForm: Platform }) => {

  return (
    <ToggleButtonGroup
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        borderRadius: '20px',
      }}
      color="primary"
      onChange={handleFormSwitch}
      value={loadedForm}
      exclusive={true}
    >
      <ToggleButton value={Platform.Vendora}>Vendora</ToggleButton>
      <ToggleButton value={Platform.Olx}>OLX</ToggleButton>
    </ToggleButtonGroup>
  );
};


export const TemplateCreation = () => {

  const [currentPlatform, setCurrentPlatform] = React.useState<Platform>(Platform.Vendora);
  const [existingTemplate, setExistingTemplate] = React.useState<Template | undefined>(undefined);

  const { showSnackbar } = useSnackbar();

  const getTemplateQuery = useQuery({
    queryKey: ['templates'],
    queryFn: () => createAuthRequest<Template>(`templates/`, 'GET')()
  })

  useEffect(() => {
    setExistingTemplate(getTemplateQuery.data);
  }, [getTemplateQuery.data]);

  const updateTemplate = async (formFields: TemplateUpdateRequest): Promise<Template | undefined> => {
    return createAuthRequest<Template>(`templates/`, 'PUT')(undefined, undefined, formFields);
  }

  const updateTemplatesMutation = useMutation({
    mutationFn: updateTemplate,
    onSuccess: (template) => {
      getTemplateQuery.refetch();
      showSnackbar('Темплейтът е обновен', 'success');
      setExistingTemplate(template);
    },
    onError: () => {
      showSnackbar('Възникна грешка при обновяването на темплейта', 'error');
    }
  });


  const handleFormSwitch = (
    _: React.MouseEvent<HTMLElement>,
    newForm: Platform,
    setFieldValue: (field: string, value: any) => void
  ) => {
    if (!newForm) { return; }
    setCurrentPlatform(newForm);
    setFieldValue('description', existingTemplate?.[newForm]?.description || '');
    setFieldValue('active', existingTemplate?.[newForm]?.active || false);
  };


  if (!existingTemplate) {
    return <Box>Loading...</Box>
  }
  const formikInitialValues = {
    description: existingTemplate?.[Platform.Vendora]?.description || '',
    active: existingTemplate?.[Platform.Vendora]?.active ?? false
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        width: '100%',
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '20px',
      }}
    >
      <Formik
        initialValues={formikInitialValues}
        onSubmit={(values) => {
          updateTemplatesMutation.mutate({
            description: values.description,
            active: values.active,
            platform: currentPlatform
          });
        }}
      >
        {props => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
              width: '100%',
              maxWidth: '1200px',
              gap: '20px', // Space between toggle and form
            }}
          >
            <Box
              sx={{
                flexShrink: 0,
              }}
            >
              <FormSwitchToggle
                handleFormSwitch={(event, newForm) =>
                  handleFormSwitch(event, newForm, props.setFieldValue)
                }
                loadedForm={currentPlatform}
              />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <form
                onSubmit={props.handleSubmit}
                style={{
                  width: '70%',
                  maxWidth: '1200px',
                }}
              >
                <Typography variant='h4'>
                  Описание
                </Typography>
                <TextField
                  type="text"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.description}
                  name="description"
                  fullWidth
                  margin="normal"
                  multiline={true}
                  minRows={10}
                  maxRows={70}
                />
                {props.errors.description && <div id="feedback">{props.errors.description}</div>}
                <Typography variant='body2'>
                  Този текст ще бъде добавен в края на описанието на всяка новосъздадена {PLATFORM_NAMES[currentPlatform]} форма.
                </Typography>
                <Button type="submit" variant="contained" sx={{ mt: 2, display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
                  Запази
                </Button>
                <FormGroup>
                  <FormControlLabel
                    checked={props.values.active}
                    onChange={(event: any) =>
                      props.setFieldValue('active', event.target.checked)}
                    control={<Checkbox />} label={`Използвай при създаването на ${PLATFORM_NAMES[currentPlatform]} форма.`} />
                </FormGroup>
              </form>
            </Box>
          </Box>
        )}
      </Formik>
    </Box>
  );
};
