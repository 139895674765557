import { ReactNode } from 'react'
import { CurrentSellerProvider } from './sellerContext'
import { EbayCategoriesProvider, OlxCategoriesProvider, VendoraCategoriesProvider } from './categoriesContext'

export const AuthenticatedUserContext = ({ children }: { children: ReactNode }) => {
  return <CurrentSellerProvider>
    <EbayCategoriesProvider>
      <OlxCategoriesProvider>
        <VendoraCategoriesProvider>
          {children}
        </VendoraCategoriesProvider>
      </OlxCategoriesProvider>
    </EbayCategoriesProvider>
  </CurrentSellerProvider >;
}