import { array, number, object, ObjectSchema, string } from 'yup';
import { AttributeDeclaration, isInputAttribute, isMultiSelectAttribute, isNumericAttribute, isSingleSelectAttribute, NumericAttribute } from '../models/category-specific-attribute';

export const mainFormValidationSchema = object({
  title: string()
    .required('Заглавието на обявата е задължително.')
    .min(16, 'Заглавието трябва да съдържа поне 16 символа.')
    .max(70, 'Заглавието трябва да бъде по-кратко от 70 символа.')
    .test('Forbidden sequences', 'Заглавието не може да съдържа тези символи повече от 3 пъти подред:' +
      '"\\", "!", "?", ".", ",", "-", "=", "+", "#", "%", "&", "@", "*", "_", ">", "<", ":", "(", ")", "|".',
      (value) => !(/[!?.,\-=#%&@*_:><\(\)|]{4,}/).test(value))
    .test('Capital letters', 'Не повече от половината букви могат да са главни,', (value) => areCapitalLettersLessThanHalf(value))
    .test('Contains email', ' Заглавието не може да съдържа имейл адреси.', (value) => !containsEmail(value)),
  price: number()
    .required('Цената е задължителна.')
    .positive('Цената трябва да бъде положително число.'),
  description: string()
    .required('Описанието на обявата е задължително.')
    .max(9000, 'Описанието трябва да бъде по-кратко от 9000 символа.')
    .min(40, 'Описанието трябва да съдържа поне 40 символа.')
    .test('Forbidden sequences', 'Заглавието не може да съдържа тези символи повече от 3 пъти подред:' +
      '"\\", "!", "?", ".", ",", "-", "=", "+", "#", "%", "&", "@", "*", "_", ">", "<", ":", "(", ")", "|".',
      (value) => !(/[!?.,\-=#%&@*_:><\(\)|]{4,}/).test(value))
    .test('Capital letters', 'Не повече от половината букви могат да са главни.', (value) => areCapitalLettersLessThanHalf(value))
    .test('Contains email', 'Описанието не може да съдържа имейл адреси.', (value) => !containsEmail(value)),
  images: array()
    .required()
    .min(1, 'Трябва да качите поне една снимка.')
    .max(12, 'Може да качите максимум 12 снимки.'),
  category: string()
    .required('Необходимо е да изберете категория.'),
  city: string()
    .required('Необходимо е да изберете град.'),
});

export const vendoraValidationSchema = object({
  title: string()
    .required('Заглавието на обявата е задължително.')
    .max(100, 'Заглавието трябва да бъде по-кратко от 100 символа.'),
  price: number()
    .required('Цената е задължителна.')
    .positive('Цената трябва да бъде положително число.')
    .max(99999999, 'Цената трябва да бъде по-малка от 99999999.'),
  description: string()
    .required('Описанието на обявата е задължително.')
    .max(10000, 'Описанието трябва да бъде по-кратко от 10000 символа.'),
  category: string()
    .required('Необходимо е да изберете категория.')
    .min(1, 'Необходимо е да изберете категория.'),
});

const containsEmail = (value: string) => {
  return value.match(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/);
}
const areCapitalLettersLessThanHalf = (value: string) => {
  const capitalLetters = value.match(/[A-ZА-Я]/g);
  return capitalLetters ? capitalLetters.length < value.length / 2 : true;
}

const createNumericAttributeValidation = ({ label, min, max, code, required }: NumericAttribute) => {
  let schema = required ? number().required(`Полето "${label.toLowerCase()}" е задължително`) : number();
  if (min) {
    schema = schema.min(min, `Минималната стойност за полето “${label.toLowerCase()}" е ${min}`);
  }
  if (max) {
    schema = schema.max(max, `Максималната стойност за полето "${label.toLowerCase()}" е ${max}`);
  }
  return { [code]: schema };
};

export const createAttributesValidationSchema = (attributes: AttributeDeclaration[]) => {
  return attributes.reduce((schema, attribute) => {
    const { label, code, required } = attribute;
    const requiredStringField = string().required(`Полето "${label.toLowerCase()}" е задължително`);
    if (isNumericAttribute(attribute)) {
      return {
        ...schema,
        ...createNumericAttributeValidation(attribute)
      };
    } else if (isSingleSelectAttribute(attribute) || isInputAttribute(attribute)) {
      return {
        ...schema,
        [code]: required ? requiredStringField : null
      }
    } else if (isMultiSelectAttribute(attribute)) {
      return {
        ...schema,
        [code]: required ? array()
          .of(requiredStringField)
          .required(`Полето "${label.toLowerCase()}" е задължително`)
          .min(1, `Полето "${label.toLowerCase()}" е задължително`) : null
      }
    }
    return { ...schema };
  }, {});
};

// блякс, но не съм задълбавал в ЧЕТИРИТЕ типови аргумента на тоя тип
// за някога
export type GenericObjectSchema = ObjectSchema<any, any, any, any>;