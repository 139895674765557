import { createContext, ReactNode, useEffect, useState } from 'react';
import createAuthRequest from '../utils/createAuthRequest';
import { useQuery } from '@tanstack/react-query';
import { Tree, TreeItem } from '../models/tree';

export const OlxCategoriesContext = createContext<Tree<TreeItem> | undefined>(undefined);
export const VendoraCategoriesContext = createContext<Tree<TreeItem> | undefined>(undefined);
export const EbayCategoriesContext = createContext<Tree<TreeItem> | undefined>(undefined);

const createCategoriesProvider = (endpoint: string, Context: React.Context<Tree<TreeItem> | undefined>) => {
  return ({ children }: { children: ReactNode }) => {
    const categoriesQuery = useQuery({
      queryKey: ['categories', endpoint],
      queryFn: () => createAuthRequest<Tree<TreeItem>>(endpoint, 'GET')()
    });

    const [categories, setCategories] = useState<Tree<TreeItem> | undefined>();

    useEffect(() => {
      if (categoriesQuery.data) {
        setCategories(new Tree(categoriesQuery.data.root));
      }
    }, [categoriesQuery.data]);

    return (
      <Context.Provider value={categories}>
        {children}
      </Context.Provider>
    );
  };
}

export const OlxCategoriesProvider = createCategoriesProvider('taxonomy/categories', OlxCategoriesContext);
export const VendoraCategoriesProvider = createCategoriesProvider('vendora/categories', VendoraCategoriesContext);
export const EbayCategoriesProvider = createCategoriesProvider('ebay/taxonomy/categories', EbayCategoriesContext);