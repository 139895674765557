import { useNavigate } from 'react-router-dom'
import createAuthRequest from './utils/createAuthRequest';
import { useQuery } from '@tanstack/react-query';
import { CircularProgress } from '@mui/material';

export const OAuthRedirectComponent = ({ path }: { path: string }) => {
  const navigate = useNavigate();

  const query = useQuery({
    queryKey: [path],
    queryFn: async () => {
      try {
        console.log(window.location.search);
        console.log('path', path);
        await createAuthRequest(`${path}${window.location.search}`, 'GET')();
        navigate('/');
        navigate(0);
      } catch (err) {
        console.error(err);
        navigate('/');
        navigate(0);
      }
    }
  });

  return <CircularProgress />;
};